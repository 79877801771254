import { useEffect, useState } from "react";
import "./Question.css";
import { Camera } from "../Camera/Camera";
import { CheckBox } from "./OptionTypes";
import { QuestionSubMenu } from "./QuestionSubMenu";
import { Bubble } from "./Bubble";
import "./Bubble.css";
import { QUESTION_STATUS } from "../../utils/constants";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import ReactDOM from "react-dom";
import Draggable from "react-draggable";
import { Focus } from "./TabFocus";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

export const QuestionPage = () => {
  const [isActive, setIsActive] = useState({
    a: false,
    b: false,
    c: false,
    d: false,
  });

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = 0.1 * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const [current, setCurrent] = useState(10);

  const [questions, setQuestions] = useState([
    { status: QUESTION_STATUS.VISITED },
    { status: QUESTION_STATUS.ANSWERED },
    { status: QUESTION_STATUS.ANSWERED },
    { status: QUESTION_STATUS.ANSWERED },
    { status: QUESTION_STATUS.MARKEDREVIEW },
    { status: QUESTION_STATUS.ANSWERED },
    { status: QUESTION_STATUS.UNVISITED },
    { status: QUESTION_STATUS.VISITED },
    { status: QUESTION_STATUS.ANSWERED },
    { status: QUESTION_STATUS.CURRENT },
    { status: QUESTION_STATUS.UNVISITED },
    { status: QUESTION_STATUS.UNVISITED },
    { status: QUESTION_STATUS.MARKEDREVIEW },
    { status: QUESTION_STATUS.UNVISITED },
    { status: QUESTION_STATUS.MARKEDREVIEW },
    { status: QUESTION_STATUS.UNVISITED },
  ]);

  const handleClick = (e) => {
    setIsActive({
      ...isActive,
      [e.target.id]: !isActive[e.target.id],
    });
  };

  const handleNext = () => {
    // Submit your answer
    // perform next question ops
  };

  const handleBubbleClick = (e, number, ref) => {
    // set current question to questionId

    ref.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });

    console.log(number);
    // const newStatus = [...questions];
    // newStatus[number - 1].status = QUESTION_STATUS.CURRENT;
    // newStatus[current - 1].status = QUESTION_STATUS.UNVISITED;
    // setQuestions(newStatus);
    // setCurrent(number);
  };

  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();

    console.log("Menu activated");
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <div onContextMenu={handleContextMenu} style={{ cursor: "context-menu" }}>
      <Typography>
        <Focus></Focus>
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ color: "#00D1FF", zIndex: "-1" }}
          />
        </Box>

        <QuestionSubMenu total={30} ok={10} review={10} skipped={10} />

        <div className="bubbleBackground">
          {questions.map(({ status }, index) => (
            <Bubble
              number={index + 1}
              key={index}
              status={status}
              onClick={handleBubbleClick}
            />
          ))}
        </div>

        <div className="ques_body row">
          <div className="question column">
            <Question
              text={
                "21. Grand Central Terminal, Park Avenue, New York is the world's"
              }
            />

            <div className="res-navigate">
              <div className="checkbox">
                <span className="box">
                  <input className="check" type="checkbox" />
                </span>
                <span className="markForReview">Mark For Review</span> <br />
              </div>

              <div className="navbtn">
                <button className="previous"> &#60; Previous </button>
                <button className="next" onClick={handleNext}>
                  Next &#62;
                </button>
              </div>
            </div>
          </div>

          <div className="question_background column">
            <div className="rollNumber">0211856</div>
            <CheckBox
              isActive={isActive.a}
              handleClick={handleClick}
              label="a"
              text="largest railway station"
            />

            <CheckBox
              isActive={isActive.b}
              handleClick={handleClick}
              label="b"
              text="largest railway station"
            />

            <CheckBox
              isActive={isActive.c}
              handleClick={handleClick}
              label="c"
              text="largest railway station"
            />

            <CheckBox
              isActive={isActive.d}
              handleClick={handleClick}
              label="d"
              text="largest railway station"
            />

            <div className="navigate">
              <div className="checkbox">
                <span className="box">
                  <input className="check" type="checkbox" />
                </span>
                <span className="markForReview">Mark For Review</span> <br />
              </div>

              <div className="navbtn">
                <button className="previous"> &#60; Previous </button>
                <button className="next" onClick={handleNext}>
                  Next &#62;
                </button>
              </div>
            </div>
          </div>
        </div>

        <Draggable bounds="html">
          <div className="cameraBox">
            <Camera />
          </div>
        </Draggable>
      </Typography>

      <Menu
      sx={{color:'grey'}}
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleClose}>Finish Test</MenuItem>
        <MenuItem onClick={handleClose}>Help</MenuItem>
        <MenuItem onClick={handleClose}>Support</MenuItem>
        <MenuItem onClick={handleClose}>Hide/Show Camera</MenuItem>
      </Menu>
    </div>
  );
};

const Question = ({ text }) => {
  return <h1 className="question">{text}</h1>;
};
