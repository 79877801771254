import * as React from 'react';
import Card from '@mui/material/Card';
import "./MediaCard.css"
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';      
export default function Mediacard() {
  return (
    <>
    <Card className="cardd">
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" color="white">
          Next Text
        </Typography>
        <Typography className='title'>
          Mathematics 1 <br/>
          <div className='titleclass'>
            <p>firday 14/10/2022</p>
            <p>code:3468</p>
          </div>
        </Typography>
      </CardContent>
    </Card>
    <br/>
    </>
  );
}
