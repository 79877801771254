import { load } from '@tensorflow-models/blazeface';
import '@tensorflow/tfjs-backend-webgl';

let model
let resHandler = console.log;

export const initFaceDetectionAlgo = async (resultHandler) => {
    if (model) return
    model = await load();
    resHandler = resultHandler
    console.log('Loaded Blazeface Model')
    return true;
}

export const predictionFunctionFaceDetectionAlgo = async (videoElement) => {
    const result = await model.estimateFaces(videoElement, false);
    resHandler(result)
}
