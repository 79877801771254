const tipIds = [4, 8, 12, 16, 20];

export const countFingers = (results) => {
    const { multiHandLandmarks } = results;

    if (!multiHandLandmarks || multiHandLandmarks === []) return -1
    for (const landmarks of multiHandLandmarks) {
        let fingers = 0;

        // Fingers
        for (let i = 1; i < 5; i++)
            if (landmarks[tipIds[i]]["y"] < landmarks[tipIds[i] - 2]["y"])
                fingers++;

        if (fingers !== 0) return fingers
        // Thumb 
        // TODO notworking 
        if (landmarks[tipIds[0]]["y"] < landmarks[tipIds[1]]["y"])
            return 5

        return 0
    }
}

const __getEuclideanDistance = (posA, posB) => Math.sqrt((posA.x - posB.x) ** 2 + (posA.y - posB.y) ** 2);
const __isThumbNearIndexFinger = (thumbPos, indexPos) => __getEuclideanDistance(thumbPos, indexPos) < 0.1;

export const simpleHandGesture = (results) => {
    const { multiHandLandmarks } = results;

    if (!multiHandLandmarks || multiHandLandmarks === []) return -1
    for (const landmarks of multiHandLandmarks) {

        let thumbIsOpen = false
        let indexIsOpen = false
        let middelIsOpen = false
        let ringIsOpen = false
        let pinkyIsOpen = false

        let pseudoFixKeyPoint = landmarks[2].x
        if (landmarks[3].x < pseudoFixKeyPoint && landmarks[4].x < pseudoFixKeyPoint)
            thumbIsOpen = true

        pseudoFixKeyPoint = landmarks[6].y
        if (landmarks[7].y < pseudoFixKeyPoint && landmarks[8].y < pseudoFixKeyPoint)
            indexIsOpen = true

        pseudoFixKeyPoint = landmarks[10].y
        if (landmarks[11].y < pseudoFixKeyPoint && landmarks[12].y < pseudoFixKeyPoint)
            middelIsOpen = true

        pseudoFixKeyPoint = landmarks[14].y
        if (landmarks[15].y < pseudoFixKeyPoint && landmarks[16].y < pseudoFixKeyPoint)
            ringIsOpen = true

        pseudoFixKeyPoint = landmarks[18].y
        if (landmarks[19].y < pseudoFixKeyPoint && landmarks[20].y < pseudoFixKeyPoint)
            pinkyIsOpen = true

        if (!thumbIsOpen && indexIsOpen && middelIsOpen && ringIsOpen && pinkyIsOpen)
            return HAND_GESTURES.FIVE

        else if (thumbIsOpen && indexIsOpen && middelIsOpen && ringIsOpen && pinkyIsOpen)
            return HAND_GESTURES.FOUR

        else if (thumbIsOpen && indexIsOpen && middelIsOpen && ringIsOpen && !pinkyIsOpen)
            return HAND_GESTURES.THREE

        else if (thumbIsOpen && indexIsOpen && middelIsOpen && !ringIsOpen && !pinkyIsOpen)
            return HAND_GESTURES.TWO

        else if (thumbIsOpen && indexIsOpen && !middelIsOpen && !ringIsOpen && !pinkyIsOpen)
            return HAND_GESTURES.ONE

        else if (!thumbIsOpen && indexIsOpen && !middelIsOpen && !ringIsOpen && pinkyIsOpen)
            return HAND_GESTURES.ROCK

        else if (thumbIsOpen && indexIsOpen && !middelIsOpen && !ringIsOpen && pinkyIsOpen)
            return HAND_GESTURES.SPIDERMAN

        else if (!thumbIsOpen && !indexIsOpen && !middelIsOpen && !ringIsOpen && !pinkyIsOpen)
            return HAND_GESTURES.FIST

        else if (thumbIsOpen && !indexIsOpen && !middelIsOpen && !ringIsOpen && !pinkyIsOpen)
            return HAND_GESTURES.THUMB

        else if (!indexIsOpen && middelIsOpen && ringIsOpen && pinkyIsOpen && __isThumbNearIndexFinger(landmarks[4], landmarks[8]))
            return HAND_GESTURES.OK
        // For debugging
        // console.log({ pinkyIsOpen, ringIsOpen, middelIsOpen, indexIsOpen, thumbIsOpen, })
    }
}

export const HAND_GESTURES = {
    OK: "OK",
    THUMB: "THUMB",
    FIST: "FIST",
    SPIDERMAN: "SPIDERMAN",
    ROCK: "ROCK",
    ONE: "ONE",
    TWO: "TWO",
    THREE: "THREE",
    FOUR: "FOUR",
    FIVE: "FIVE",
}