import { useEffect, useState } from "react";
import { useModal } from "react-hooks-use-modal";
import { delay } from "../../helpers/delay";
import { warningToast } from "../../utils/toast";

export const Focus = () => {
  const [Modal, openModal, closeModal] = useModal("root", {
    preventScroll: true,
    focusTrapOptions: {
      clickOutsideDeactivates: false,
    },
  });

  const [tabHasFocus, setTabHasFocus] = useState(true);

  useEffect(() => {
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  const handleFocus = async () => {
    if (tabHasFocus) return;
    console.log("Tab has focus");
    await delay(500);
    closeModal();
    warningToast("App / tab change detected !!");
    setTabHasFocus(true);
  };

  const handleBlur = () => {
    console.log("Tab lost focus");
    setTabHasFocus(false);
    openModal();
  };

  return (
    <Modal>
      <div className="modal">
        Further navigations will disqualify you from the exam
      </div>
    </Modal>
  );
};
