import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react"
import App from "./App";
import { AUTH0_CLIENTID, AUTH0_DOMAIN } from "./utils/constants";
import { Cursor } from './components/Cursor/Cursor';
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <Auth0Provider domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENTID}
      audience={'TexamBackendAPI'}
      useRefreshTokens={true}
      redirectUri={`${window.location.origin}/dashboard`}
      cacheLocation="localstorage" // <-- add this config
    >
      <App />
      <Cursor />
    </Auth0Provider>
  </StrictMode>
);
