import React, { useEffect, useState } from "react";
import login_logo from "../../assets/login_logo.jpg";
import "./Login.css";
//For Modals Testing
import { useModal } from "react-hooks-use-modal";
import { useAuth0 } from "@auth0/auth0-react";
import { errorToast } from "../../utils/toast";
import axios from "axios";

import { sampleQue, useQuestions } from "../../hooks/redux";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //for setting auth0
  const { loginWithRedirect, accessToken, getAccessTokenSilently } = useAuth0();

  const [state, dispatch] = useQuestions();

  const navigate = useNavigate();
  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: true,
    focusTrapOptions: {
      clickOutsideDeactivates: false,
    },
  });
  const submitForm = (e) => {
    e.preventDefault(); // To prevent auto refresh
  };

  const handleLoginButtonClick = () => {
    // return loginWithRedirect();
    navigate("/instruction");
  };
  const handleNotifyClick = () => {
    errorToast();
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  return (
    <div className="login">
      <img className="login_logo" src={login_logo} alt="Login Logo"></img>
      <h1 className="userlogin">User Login</h1>
      <form className="formWrapper" action="" onSubmit={submitForm}>
        <div>
          <label htmlFor="email" />
          <input
            className="user-details"
            placeholder="Email"
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password" />
          <input
            className="user-details"
            placeholder="Password"
            type="password"
            name="password"
            id="password"
            autoComplete="off"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </form>
      <div className="buttonWrapper">
        <button
          type="submit"
          className="loginbutton"
          onClick={handleLoginButtonClick}
        >
          <span className="loginText">Login</span> 
        </button>
        <button className="loginbutton" onClick={handleNotifyClick}>
          Notify!
        </button>
        <button
          className="loginbutton"
          onClick={() => dispatch({ type: "set", payload: sampleQue })}
        >
          dispatch
        </button>
        <div>
          <p>Modal is Open? {isOpen ? "Yes" : "No"}</p>
          <button onClick={open}>OPEN</button>
          <Modal>
            <div className="modal">
              <h1>Title</h1>
              <h2 className="h2">
                Are you sure you <br />
                you want to leave
              </h2>
              <div className="buttondiv">
                <button className="modalbutton" onClick={close}>
                  CLOSE
                </button>
                <button className="modalbutton2">STAY</button>
              </div>
            </div>
          </Modal>
        </div>
        <button
          className="loginbutton"
          onClick={() => {
            getAccessTokenSilently().then((token) =>
              axios
                .get("http://localhost:3001/test", {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .then(console.log)
            );
          }}
        >
          axios
        </button>
      </div>
    </div>
  );
};
export default Login;
