export const PROTECTED_ROUTES = ['/configuration', '/question', '/instruction', '/waiting', '/exit', '/dashboard'];

export const QUESTION_STATUS = {
    CURRENT: 'CURRENT',
    VISITED: 'VISITED',
    MARKEDREVIEW: 'MARKEDREVIEW',
    ANSWERED: 'ANSWERED',
    UNVISITED: 'UNVISITED',
}

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENTID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AI_PROCESSING_FPS = 4; // Frames per second
export const AI_EVENT_TRIGRING_THRESHOLD = 2 * AI_PROCESSING_FPS // Seconds * fps = frames

export const BACKEND_URL =
    window.location.hostname === 'localhost'
        ? 'http://localhost:5050/api'
        : `${window.location.origin}/api`;
