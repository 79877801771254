import reviewImg from "../../assets/review.png";
import OkImg from "../../assets/Ok.png";
import DateTime from "../Header/dateTime";
import cancelImg from "../../assets/Cancel.png";
import { useNavigate } from "react-router-dom";
import "./QuestionSubMenu.css";

export const QuestionSubMenu = ({ total, ok, review, skipped }) => {

  const navigate = useNavigate();
  const handleFinishButton = () => {
    navigate("/exit");
  };

  return (
    <div className="subMenu">
      <span className="institute">CTAE, Udaipur</span>

      <span className="total">
        <span className="total long-text">Total:{total}</span>
      </span>

      <span className="common okNum">
        <img className="commonNumber short-text" src={OkImg} alt="okImage" />
        <span className="commonNumber long-text">Answered: </span>
        <span status="answered">{ok}</span>
      </span>

      <span className="common reviewNum">
        <img
          className="commonNumber short-text"
          src={reviewImg}
          alt="reviewImage"
        />
        <span className="commonNumber long-text">Marked for review: </span>
        <span status="markedReview">{review}</span>
      </span>

      <span className="common cancelNum">
        <img
          className="commonNumber short-text"
          src={cancelImg}
          alt="cancelImage"
        />
        <span className="commonNumber long-text">Skipped: </span>
        <span status="unvisited">{skipped}</span>
      </span>

      <span>
        <button className="finish" onClick={handleFinishButton}>Finish</button>
        </span>
        
    </div>
  );
};
