import React, { useCallback } from "react";
import "./Configuration.css";
import Headphone from "../../assets/headphone.png";
import CamPic from "../../assets/camPic.png";
import { useNavigate } from "react-router-dom";
import { useSharedUserMedia } from "../../hooks/useUserMedia";
import { useRef } from "react";
import { useEffect } from "react";
import Webcam from "react-webcam";
import { isMobile } from "react-device-detect";
import { useState } from "react";
import { useSharedVideoConstraints } from "../../hooks/videoConstraintsHook";
import { initAlgos } from "../../utils/ai";
import { errorToast, promiseToast, warningToast } from "../../utils/toast";

export const Configuration = () => {
  const navigate = useNavigate();
  const [devices, setDevices] = useState([]);
  const [videoConstraints, setVideoConstraints] = useSharedVideoConstraints();
  const [modelsInit, setModelsInit] = useState(false);

  useEffect(() => {
    // TODO: add loader and await this
    promiseToast(
      initAlgos,
      {
        loading: "Models Loading ...",
        success: "Models Loaded, you can proceed.",
        error: "Someting went wrong(models not loaded).",
      },
      (status) => setModelsInit(status)
    );
  }, []);

  const handleDevices = useCallback(
    (mediaDevices) => {
      const d = mediaDevices.filter(({ kind }) => kind === "videoinput");
      setDevices(d);
      if (!isMobile)
        setVideoConstraints((constraints) => {
          return { ...constraints, deviceId: d[0] };
        });
    },
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const handleVideoSelectChange = (e) => {
    setVideoConstraints((constraints) => {
      return { ...constraints, deviceId: e.target.value };
    });
  };

  const handleProceedButton = () => {
    if (!modelsInit)
      return warningToast("Ai model(s) not loaded, Please wait.");
    navigate("/waiting");
  };

  return (
    <div>
      <h1 className="heading">Camera Mic Configuration</h1>

      <Webcam
        className="cameraConfig"
        mirrored="false"
        // onUserMedia={console.log}
        onUserMediaError={console.error}
        audio={false}
        videoConstraints={videoConstraints}
      />

      <div className="align">
        {/* <div className="micButton">
          <img className="headphone" src={Headphone} alt="headphone" />
          <select
            className="micText dropdown"
            name="Mic"
            onChange={handleAudioSelectChange}
          >
            {userMediaDevices.audio.map((device, index) => (
              <option id={index} value={device.deviceId}>
                {device.label}
              </option>
            ))}
          </select>
        </div> */}

        {!isMobile && (
          <div className="camButton">
            <img className="camPic" src={CamPic} alt="camPic" />
            <select
              className="camText"
              name="Camera"
              onChange={handleVideoSelectChange}
            >
              {devices.map((device, index) => (
                <option id={index} value={device.deviceId}>
                  {device.label}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <button className="proceedConfig" onClick={handleProceedButton}>
        Proceed
      </button>
    </div>
  );
};
