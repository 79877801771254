// import topCamImg from "../../assets/topcam.png";
import "./Header.css";
import TexamLogo from "../../assets/Texam-logo.png";
import mini_logo from "../../assets/mini_logo.jpg";
import { PROTECTED_ROUTES } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import { Account } from "../Account/accountHandle";
// import { useNavigate } from "react-router-dom";
//import { MenuDemo } from "./Menu";
import DateTime from "./dateTime";

export const Header = () => {
  const { pathname } = useLocation();
  // const navigate = useNavigate();

  return (
    <div className="site-header">
      {!PROTECTED_ROUTES.includes(pathname) ? (
        <img className="logo" src={TexamLogo} alt="TexamLogoImage" />
      ) : (
        <div className="navbar">
          {/*<MenuDemo />*/}
          <img className="res-logo" src={TexamLogo} alt="TexamLogoImage" />
          <img className="mini_logo" src={mini_logo} alt="LogoImage" />
          <h1 className="time-header">
            <DateTime></DateTime>
          </h1>
          <Account></Account>
        </div>
      )}
    </div>
  );
};
