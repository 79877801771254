import React from "react";
import "./ErrorPage.css";
import ErrorGif from "../../assets/GIF_404_Error.gif";

export const ErrorPage = () => {
  return (
    <div>
      <div className="errorVideoWrapper">
          <img className="errorGif" src={ErrorGif} alt="Sorry, your browser doesn't support GIFs."/>
          <p><button className="back">Back to Home</button></p>
      </div>
    </div>
  );
};

