import React, { useRef, useState } from "react";
import "./Waiting.css";
import timerpicImg from "../../assets/timerpic.svg";
import { TimerCount } from "./TimerCount";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export const WaitingPage = () => {
  const navigate = useNavigate();
  const timeoutHandler = () => navigate("/question");

  const [progress, setProgress] =useState(0);

  const intervalHandler = (interval)=>{
    setProgress((oldProgress) => {
      if (oldProgress === 100) {
        return 0;
      }
      const diff = 1 * 10;
      return Math.min(oldProgress + diff, 100);
    });
  }

  return (
    <div>

   <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} sx={{ color: '#00D1FF'}} />
  </Box>

       <div> <img className="timerpic" src={timerpicImg} alt="timerpicImage" />
    <h1 className="timerText">
       Just keep calm and relax Your exam will start shortly
    </h1>
   <TimerCount timeoutHandler={timeoutHandler} intervalHandler={intervalHandler} />
</div>
    </div>
   
  );
};
