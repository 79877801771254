import { AI_EVENT_TRIGRING_THRESHOLD, AI_PROCESSING_FPS } from "../constants"
import { errorToast, infoToast, warningToast } from "../toast";
import { initFaceDetectionAlgo, predictionFunctionFaceDetectionAlgo } from "./face";
import { initHandDetectionAlgo, predictionFunctionHandDetectionAlgo } from "./hand";
import { countFaces, getFaceDistance } from "./helpers/face";
import { countFingers, HAND_GESTURES, simpleHandGesture } from "./helpers/fingers";

let predictionEventLoop;

export let init = false;

export const initAlgos = async () => {
    try {
        if (init) return true;
        // init algos
        const res = await Promise.all([
            initFaceDetectionAlgo(faceResultHandler),
            initHandDetectionAlgo(handResultHandler)
        ])

        for (let status of res) if (!status) return status;
        init = true;
        return true;
    } catch (err) {
        errorToast('Some unknown error, pelase refresh the page')
    }
}

// frame = video ref
export const startPrediction = (videoElement) => {
    predictionEventLoop = setInterval(sendFrameForPrediction, 1000 / AI_PROCESSING_FPS, videoElement)
}

export const stopPrediction = () => {
    clearInterval(predictionEventLoop);
}

const sendFrameForPrediction = (frame) => {
    // code to send each frame for prediction by each model
    predictionFunctionFaceDetectionAlgo(frame)
    predictionFunctionHandDetectionAlgo(frame)
}

const faceResultHandler = (result) => {
    const faces = countFaces(result);
    const distance = getFaceDistance(result);

    c2.inc(faces, AI_EVENT_TRIGRING_THRESHOLD / 2, () => {
        if (faces > 1) warningToast('Multiple face detected');
        if (!faces) warningToast('Face not detected')

        console.log(faces)
    })
    // console.log({ faces, distance })
}

const handResultHandler = (result) => {
    if (!result) return;
    // const fingers = countFingers(result)
    // if (!fingers && fingers !== 0) return;

    // c1.inc(fingers, AI_EVENT_TRIGRING_THRESHOLD, () => {
    //     if (fingers === -1) return
    //     if (fingers === 5) return infoToast(`Thums up 👍🏻`)
    //     if (fingers === 0) return infoToast(`Box 👊`)
    //     infoToast(`Selected ${fingers} option.`)
    //     console.log(fingers)
    // })
    // console.log('                   ', fingers)

    const gesture = simpleHandGesture(result);
    c1.inc(gesture, AI_EVENT_TRIGRING_THRESHOLD, () => {
        if (!HAND_GESTURES[gesture]) return;
        infoToast(`Selected ${gesture} option.`)
        console.log(gesture)
    })

}

const getCounter = () => {
    const counter = () => {
        let count = 0;
        let startValue;

        const inc = (value, max, cb) => {
            if (!count) startValue = value;
            if (startValue !== value) return count = 0
            if (count === max) { count = 0; return cb(); }
            count++;
        }
        return { inc, count }
    }

    return counter();
}

const c1 = getCounter();
const c2 = getCounter();
const c3 = getCounter();
