const datatest=[
    {
        test:"Test 2",
        subject:"chemistry",
        code:"code:3468",
        start:"start date: 05/04/23",
        end:"end date : 16/04/23",
        time:"timing:9:00AM to 9:00PM",
    },
    {
        test:"Test 2",
        subject:"chemistry",
        code:"code:3468",
        start:"start date: 05/04/23",
        end:"end date : 16/04/23",
        time:"timing:9:00AM to 9:00PM",
    },
    {
        test:"Test 2",
        subject:"chemistry",
        code:"code:3468",
        start:"start date: 05/04/23",
        end:"end date : 16/04/23",
        time:"timing:9:00AM to 9:00PM",
    },
    {
        test:"Test 2",
        subject:"chemistry",
        code:"code:3468",
        start:"start date: 05/04/23",
        end:"end date : 16/04/23",
        time:"timing:9:00AM to 9:00PM",
    },
    {
        test:"Test 2",
        subject:"chemistry",
        code:"code:3468",
        start:"start date: 05/04/23",
        end:"end date : 16/04/23",
        time:"timing:9:00AM to 9:00PM",
    },
    {
        test:"Test 2",
        subject:"chemistry",
        code:"code:3468",
        start:"start date: 05/04/23",
        end:"end date : 16/04/23",
        time:"timing:9:00AM to 9:00PM",
    },
    {
        test:"Test 1",
        subject:"chemistry",
        code:"code:3468",
        start:"start date: 05/04/23",
        end:"end date : 16/04/23",
        time:"timing:9:00AM to 9:00PM",
    },
    {
        test:"Test 2",
        subject:"chemistry",
        code:"code:3468",
        start:"start date: 05/04/23",
        end:"end date : 16/04/23",
        time:"timing:9:00AM to 9:00PM",
    },
    {
        test:"Test 2",
        subject:"chemistry",
        code:"code:3468",
        start:"start date: 05/04/23",
        end:"end date : 16/04/23",
        time:"timing:9:00AM to 9:00PM",
    },
]
export default datatest;