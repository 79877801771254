import { useReducer } from "react";
import { useBetween } from "use-between";

export const sampleQue = [
    { text: `quesiton 2`, id: 102 },
    { text: `quesiton 1`, id: 101 },
]

const initialState = {
    que: [],
    dCount: 0
};

function reducer(state, action) {
    switch (action.type) {
        case 'set':
            return { que: action.payload, dCount: state.dCount + 1 };
        default:
            throw new Error();
    }
}

const useQuestionReducer = () => {
    // return [state, dispatch]
    return useReducer(reducer, initialState);
}

export const useQuestions = () => useBetween(useQuestionReducer)
