import React, { useState } from "react";
import "./Dashboard.css";
import { Calender } from "./Calendar";
import Cards from "./Cards";
import datatest from "./data";
import SearchIcon from '@mui/icons-material/Search';
import { CardContent } from "@mui/material";
export const Dashboard = ({ placeholder, data }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord)
    console.log(searchWord)
  };
  return (
    <>
      <div className="search">
        <div className="searchdiv">
          <div className="searchInputs">
            <input className='input'
              type="text"
              placeholder="search"
              value={wordEntered}
              onChange={handleFilter}
            />
            <div className='icondiv'><CardContent><SearchIcon onClick={()=> console.log("click")} className="searchicon" /></CardContent></div>
          </div>
        </div>
      </div>
      <div className="grid-container">
        <div>
          <Calender />
        </div>
        <div className="rightside">
          {datatest?.filter((e)=>{
            if(wordEntered.length>0){
              console.log(e.test)
              return e.test.toLowerCase().includes(wordEntered.toLowerCase())
            }
            return e;
          }).map((e) => {
            return (<div className='upcard margin'><Cards subject={e.subject} test={e.test} code={e.code} start={e.start} end={e.end} time={e.time} /></div>)
          })}

        </div>
      </div>
    </>
  );
}


