import { useState } from 'react';
import Calendar from 'react-calendar';
import './Calendar.css';
import Mediacard from './MediaCard';
export const Calender = () => {
  const [date, setDate] = useState(new Date());
  return (
    <div className='app'>
    <div className='calendar-container'>
      <Calendar onChange={setDate} value={date} className="react-calendar"/>
      <h2 className='announcement'>Announcements</h2>
      <br/>
      <div className='mediacards'>
      <Mediacard/>
      <Mediacard/>
      <Mediacard/>
      </div>
    </div>
  </div>
);
};
 


