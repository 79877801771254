import { toast } from 'react-toastify';

const options = {
    position: toast.POSITION.BOTTOM_RIGHT,
    closeOnClick: true,
    pauseOnHover: true,
    theme: "dark",
    autoClose: 5000,
    hideProgressBar: false,
    draggable: true,
    progress: undefined,
}

export const errorToast = (msg) => {
    if ((typeof msg !== typeof 'String') || !msg) msg = 'Someting went wrong.';
    toast.error(msg, options);
}

export const warningToast = (msg) => {
    if ((typeof msg !== typeof 'String') || !msg) msg = 'Warning!';
    toast.warn(msg, options);
}

export const infoToast = (msg) => {
    if ((typeof msg !== typeof 'String') || !msg) msg = 'Please reach out to us at info@texam.in';
    toast.info(msg, options);
}

export const successToast = (msg) => {
    if ((typeof msg !== typeof 'String') || !msg) msg = 'Updated Successfully';
    toast.success(msg, options);
}

export const promiseToast = async (promise, { loading, success, error }, callback, param1, param2) => {
    const response = await toast.promise(promise, {
        pending: {
            render() {
                return loading || 'Promise is pending'
            },
            ...options
        },
        success: {
            render() {
                return success || 'Promise resolved 👌'
            },
            ...options
        },
        error: {
            render() {
                return error || 'Promise rejected 🤯'
            },
            ...options
        },
    })
    callback(response, param1, param2);
    return response;
} 