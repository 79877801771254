
export const countFaces = (prediction) => {
    if (prediction) return prediction.length
}

export const getFaceDistance = (prediction) => {
    if (!prediction || !prediction.length) return;
    // const focal_length_found = 948.2517482517482;
    // const focal_length_found = 255.94405594405592;
    const focal_length_found = 1283.9160839160838;
    const know_width = 14.3
    const face_width_in_frame = face_data(prediction[0])
    const dist = distance_finder(focal_length_found, know_width, face_width_in_frame)
    return dist;
}

const focal_length = (measured_distance, real_width, width_in_rf_image) =>
    (width_in_rf_image * measured_distance) / real_width

const distance_finder = (focal_length, real_face_width, face_width_in_frame) =>
    (real_face_width * focal_length) / face_width_in_frame

const face_data = ({ bottomRight, topLeft }) =>
    bottomRight[0] - topLeft[0];