import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const TimerCount = ({
  initialMinute = 0,
  initialSeconds = 10,
  timeoutHandler,
  intervalHandler = () => {},
}) => {
  const navigate = useNavigate();
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  const timerHandler = (interval)=>{
    if (seconds > 0) {
      setSeconds(seconds - 1);
    }
    if (seconds === 0) {
      if (minutes === 0) {
        clearInterval(interval);
      } else {
        setMinutes(minutes - 1);
        setSeconds(59);
      }
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      intervalHandler(interval);
      timerHandler(interval);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  if (minutes === 0 && seconds === 0)
    if (timeoutHandler) return timeoutHandler();
    else return navigate("/question");

  return (
    <div
      className="time-live"
      onClick={() => {
        if (process.env.NODE_ENV === "development" && timeoutHandler)
          timeoutHandler();
      }}
    >
      <h1>
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </h1>
    </div>
  );
};
