import { useCallback, useEffect, useRef } from "react";
import { QuestionPage } from "./components/Question/Question";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorPage } from "./components/ErrorPage/ErrorPage";
import { Login } from "./components/Login/Login";
import { Instructions } from "./components/Instructions/Instructions";
import { Configuration } from "./components/Configuration/Configuration";
import { WaitingPage } from "./components/Waiting/Waiting";
import { Header } from "./components/Header/Header";
import { Exit } from "./components/ExitPage/ExitPage";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";
import { Dashboard } from "./components/Dashboard/Dashboard";
// import { socket } from "./utils/socket.io";

const App = () => {

  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user,
    logout,
    error,
    getAccessTokenSilently,
  } = useAuth0();

  const getToken = useCallback((options = {}) => {
    console.log(`retrieving token`);
    getAccessTokenSilently(options)
      .then((token) => {
        console.log("retrieved token!", token);
      })
      .catch((e) => {
        console.error("failed to get token!", e);
      });
  }, []);

  useEffect(() => { console.log(user) })
  useEffect(() => {
    // Uncomment while testing
    window.logout = () => logout({ returnTo: window.location.origin })
    if (isAuthenticated && process.env.NODE_ENV === "development") getToken({});
  }, [isAuthenticated]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Oops... {error.message}</div>;
  if (!isAuthenticated) return loginWithRedirect();




  return (
    <BrowserRouter >
      <Header />
      <div className="site">
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* TODO: implement dashboard  */}
      
          <Route path="/instruction" element={<Instructions />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/question" element={<QuestionPage />} />
          <Route path="/waiting" element={<WaitingPage />} />
          <Route path="/exit" element={<Exit />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
