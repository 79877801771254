import { useRef, useState } from "react";
import { useEffect } from "react";
import { useSharedUserMedia } from "../../hooks/useUserMedia";
import { useSharedVideoConstraints } from "../../hooks/videoConstraintsHook";
import Webcam from "react-webcam";
import {
  init,
  initAlgos,
  startPrediction,
  stopPrediction,
} from "../../utils/ai";

import "./Camera.css";
import { useNavigate } from "react-router-dom";
import { errorToast, promiseToast } from "../../utils/toast";

export const Camera = () => {
  // const videoRef = useRef(null);

  // const { setVideoRef } = useSharedUserMedia();

  // useEffect(() => {
  //   setVideoRef(videoRef);
  // }, []);

  // return <video ref={videoRef} className="camera"></video>;
  const navigate = useNavigate();

  const [videoConstraints] = useSharedVideoConstraints();
  const videoElement = useRef(null);

  useEffect(() => {
    if (!init) {
      errorToast("Error Ai models not loaded !");
      stopPrediction();
      navigate("/configuration");
    }
  }, [navigate]);

  const onStream = (s) => {
    startPrediction(videoElement.current.video);
  };

  return (
    <Webcam
      ref={videoElement}
      className="camera"
      mirrored="false"
      onUserMedia={onStream}
      onUserMediaError={console.log}
      audio={false}
      videoConstraints={videoConstraints}
    />
  );
};
