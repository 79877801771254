import * as React from 'react';
import {useState} from "react"
import Card from '@mui/material/Card';
import "./Cards.css"
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {useNavigate} from "react-router-dom"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
export default function Rightside(props) {
  const [expanded, setExpanded] =useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
const navigate=useNavigate()
const start = ()=>{
  navigate("/configuration")
  console.log("click hua")

}
  return (
    <>
    <Card className="cards">
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" color="white">
          {props.test}
          <hr className='line' />
          </Typography>
        <Typography className="title">
          <div className='titleclass'>
            <p>{props.subject}</p>
            <p>{props.code}</p>
          </div>
        </Typography>
      </CardContent>
        <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          className='expand'
        >
          <ExpandMoreIcon className='expand' />
        </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Typography className='title'>
          <div className='datas'>
            <p>{props.start}<br/>{props.end} <br/>{props.time}</p>
          </div>
        </Typography>
        <CardActions>
        <div className='middlebuttondiv'>
        <button className="middlebutton">RESCHEDULE TEST</button>
        <button className="middlebutton2" onClick={start}>START TEST</button>
        
        </div>
      </CardActions>

      </Collapse>
    </Card>
    </>
  );
}

